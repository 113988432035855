import React from "react";
import { Container, Row } from "react-bootstrap";

function CardResultFailure() {
  return (
    <Container>
      <Row className="justify-content-center rp-title">
        Payment method failed
      </Row>
      <Row className="justify-content-center rp-desc">
        Sorry, we were unable to add your payment method.
      </Row>
      <Row className="justify-content-center rp-desc">
        Please check your details and if the problem persists contact customer
        support and we’ll do our best to help.
      </Row>
    </Container>
  );
}
export default CardResultFailure;
