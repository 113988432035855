export const TRANSLATIONS = [
  {
    name: 'en',
    translations: {
      'Password Reset': 'Password Reset',
      'Please enter your email address and new password.':
        'Please enter your email address and new password.',
      'Email address': 'Email address',
      Password: 'Password',

      'Confirm Password': 'Confirm Password',
      Submit: 'Submit',
      'Please provide a valid email': 'Please provide a valid email',
      'Password should be 8 characters or more': 'Password should be 8 characters or more',
      'The password must contain at least 1 lowercase, 1 uppercase, and 1 numeric character':
        'The password must contain at least 1 lowercase, 1 uppercase, and 1 numeric character',
      'Passwords do not match': 'Passwords do not match',
      'Error when trying to reset password, please try again':
        'Error when trying to reset password, please try again',
      'You have successfully updated your password, please return to the app and log in.':
        'You have successfully updated your password, please return to the app and log in.',
      SMS: 'SMS',
      Email: 'Email',
      'Push Notifications': 'Push Notifications',
      'All of the above': 'All of the above',
      'Update Preferences': 'Update Preferences',
      'unsubscribe-title': 'How would you like to be contacted?',
      'unsubscribe-desc':
        'To unsubscribe from marketing, leave your preferences empty and click Update Preferences.',
      'unsubscribe-footer-line1':
        'You can update your preferences at any time from your account profile.',
      'unsubscribe-footer-line2':
        'Clicking Update Preferences will override your existing contact preferences. Please note it can take up to 24 hours to update.',
      'Thank you, your preferences have been updated.':
        'Thank you, your preferences have been updated.',
      'Error when trying to update preferences, please try again':
        'Error when trying to update preferences, please try again',
    },
  },
  {
    name: 'fr',
    translations: {
      'Password Reset': 'Réinitialisation du mot de passe',
      'Please enter your email address and new password.':
        'Veuillez SVP entrer votre adresse courriel et votre nouveau mot de passe.',
      'Email address': 'Adresse courriel',
      Password: 'Mot de passe',
      'Confirm Password': 'Confirmez le mot de passe',
      Submit: 'Soumettre',
      'Please provide a valid email': 'Veuillez SVP fournir une adresse courriel valide',
      'Password should be 8 characters or more':
        'Le mot de passe doit comporter 8 caractères ou plus',
      'The password must contain at least 1 lowercase, 1 uppercase, and 1 numeric character':
        'Le mot de passe doit contenir au moins 1 minuscule, 1 majuscule et 1 caractère numérique',
      'Passwords do not match': 'Les mots de passe ne correspondent pas',
      'Error when trying to reset password, please try again':
        'Erreur lors de la tentative de réinitialisation du mot de passe, veuillez réessayer',
      'You have successfully updated your password, please return to the app and log in.':
        "Vous avez réussi à mettre à jour votre mot de passe, veuillez retourner sur l'application et vous connecter.",
    },
  },
  {
    name: 'da',
    translations: {
      'Password Reset': 'Nulstil kodeord',
      'Please enter your email address and new password.':
        'Indtast venligst din e-mailadresse og ny adgangskode.',
      "Your password has now been changed. Log in with your email and new password.": "Dit kodeord er nu ændret. Log ind med din e-mail og nye kodeord.",
      'Email address': 'E-mail',
      Password: 'Password',
      'Confirm Password': 'Bekræft kodeord',
      Submit: 'Submit',
      SMS: 'SMS',
      Email: 'E-mail',
      'Update Preferences': 'Gem kontakttilladelser',
      'unsubscribe-title': 'Hvordan vil du gerne kontaktes?',
      'unsubscribe-desc':
        'For at afmelde marketing skal du lade dine præferencer være tomme og klikke på Opdater præferencer.',
      'unsubscribe-footer-line1':
        ' Du kan til enhver tid opdatere dine præferencer fra din kontoprofil.',
      'unsubscribe-footer-line2':
        'Hvis du klikker på Opdater præferencer, tilsidesættes dine eksisterende kontaktpræferencer. Bemærk, at det kan tage op til 24 timer at opdatere.',
    },
  },
];
