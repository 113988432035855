import React, { useEffect, useState } from "react";
import { Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

export const Logo = () => {
  const { tenant } = useParams();
  const [logoSrc, setLogoSrc] = useState('');

  useEffect(() => {
    if (tenant.toLowerCase() === 'ufdenmark') {
      setLogoSrc('/big-super-fantasy-logo.png');
    }
    if (tenant.toLowerCase() === 'low6-ultfan') {
      setLogoSrc('/ultfan-logo.png');
    }
  }, [tenant]);

  if (!logoSrc) return null

  return (
    <Row className="justify-content-center rp-logo mb-2">
      <Image src={logoSrc} alt="logo" className="reset-logo w-25"/>
    </Row>
  )
};