import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import DefaultPage from "./components/DefaultPage";
import ResetPassword from "./components/ResetPassword";
import CardResultSuccess from "./components/CardResultSuccess";
import CardResultFailure from "./components/CardResultFailure";
import Unsubscribe from "./components/Unsubscribe";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<DefaultPage />} />
          <Route
            exact
            path="/reset-password/:lang/:env/:tenant/:token"
            element={<ResetPassword />}
          />
          <Route
            exact
            path="/unsubscribe/:lang/:env/:tenant/:token"
            element={<Unsubscribe />}
          />
          <Route
            exact
            path="/card-result/success"
            element={<CardResultSuccess />}
          />
          <Route
            exact
            path="/card-result/failure"
            element={<CardResultFailure />}
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
