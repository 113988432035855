import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Helmet } from 'react-helmet-async';
import { getTranslations } from "../helpers/getTranslations";
import { getEnvApi } from "../helpers/getEnvApi";
import { Logo } from "./Logo";

function Unsubscribe() {
  const { lang, env, tenant, token } = useParams();

  const [langState, setLangState] = useState(lang);
  const localization = getTranslations(langState);
  const envApiEndpoint = getEnvApi(env);

  const [logoPathSvg, setLogoPathSvg] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [checkboxes, setCheckboxes] = useState({
    email: false,
    sms: false,
    pushNotifications: false,
    all: false,
  });

  const betrivers = ['low6-betrfree', 'low6-betrbtb', 'low6-betrchl'];
  const bet99 = ['low6-bet99otr', 'low6-bet99qbc', 'low6-bet99roc'];

  const handleCheckboxChange = (e) => {
    let checkboxesUpdated = checkboxes;
    if (e.target.id === 'all' && e.target.checked) {
      checkboxesUpdated = {
        email: true,
        sms: true,
        pushNotifications: true,
        all: true,
      };
    } else {
      checkboxesUpdated[e.target.id] = e.target.checked;
    }
    setCheckboxes({ ...checkboxesUpdated });
  };

  const handleSubmitClick = async (event) => {
    console.log(checkboxes);
    event.preventDefault();

    setIsLoading(true);
    const headers = { 'x-app-id': tenant };
    axios
      .post(
        `${envApiEndpoint}/perm?token=${token}`,
        {
          contactPermissions: {
            emailNotificationsAccepted: checkboxes['email'],
            inAppNotificationsAccepted: checkboxes['pushNotifications'],
            smsNotificationsAccepted: checkboxes['sms'],
          },
        },
        {
          headers,
        }
      )
      .then((res) => {
        console.log(res);
        console.log(res.data);
        setIsLoading(false);
        setIsSuccess(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsSuccess(false);
        setErrorMessage(error.response?.data?.message);
      });
  };

  // TODO: Move this to a helper function
  useEffect(() => {
    if (betrivers.includes(tenant.toLowerCase())) {
      setLogoPathSvg('/betrivers.png');
      document.body.style = 'background: #091A34';
    } else if (bet99.includes(tenant.toLowerCase())) {
      setLogoPathSvg('/bet99.svg');
      document.body.style = 'background: #030303';
    } else if (tenant.toLowerCase() === 'low6-animoca') {
      setLogoPathSvg('/animoca-logo.svg');
    } else if (tenant.toLowerCase() === 'low6-ultfan' && env === 'eu') {
      setLogoPathSvg('/ultfan.png');
      document.body.style =
        'background: url("/ultfan-background.png") no-repeat fixed; background-size:cover';
    } else if (tenant.toLowerCase() === 'ufdenmark') {
      setLogoPathSvg('/big-super-fantasy-logo.png');
      setLangState('da');
    } else {
      setLogoPathSvg('/dhan-dhoom-logo.png');
    }
  }, [tenant]);

  return (
    <>
      <Helmet>
        <title>Unsubscribe</title>
      </Helmet>

      <Container>
        <Container className="unsubscribe-main-container">
          {isLoading && isSuccess === null && (
            <Row className="justify-content-center loading-container">
              <div className="spinner"></div>
            </Row>
          )}

          {!isLoading && isSuccess === false && (
            <Row className="justify-content-center result-container">
              <Alert key={'danger'} variant={'danger'}>
                {localization['Error when trying to update preferences, please try again']}:{' '}
                {errorMessage}
              </Alert>
            </Row>
          )}
          {!isLoading && isSuccess === true && (
            <Row className="justify-content-center result-container">
              <Alert key={'success'} variant={'success'}>
                {localization['Thank you, your preferences have been updated.']}
              </Alert>
            </Row>
          )}
          {!isLoading && isSuccess === null && (
            <Container className="unsbscribe-checkboxes-container">
              <Logo/>

              <Row className="rp-title">{localization['unsubscribe-title']}</Row>
              <Row className="unsubscribe-desc">{localization['unsubscribe-desc']}</Row>
              <Row>
                <Form.Check
                  type={'checkbox'}
                  id={`email`}
                  label={localization['Email']}
                  className="unsubscribe-checkbox"
                  onChange={handleCheckboxChange}
                  checked={checkboxes['email']}
                />
              </Row>
              <Row>
                <Form.Check
                  type={'checkbox'}
                  id={`sms`}
                  label={localization['SMS']}
                  className="unsubscribe-checkbox"
                  onChange={handleCheckboxChange}
                  checked={checkboxes['sms']}
                />
              </Row>
              {/*{tenant.toLowerCase() === "low6-ultfan" && env === "eu" ? null : (*/}
              {/*  <Row>*/}
              {/*    <Form.Check*/}
              {/*      type={"checkbox"}*/}
              {/*      id={`pushNotifications`}*/}
              {/*      label={localization["Push Notifications"]}*/}
              {/*      className="unsubscribe-checkbox"*/}
              {/*      onChange={handleCheckboxChange}*/}
              {/*      checked={checkboxes["pushNotifications"]}*/}
              {/*    />*/}
              {/*  </Row>*/}
              {/*)}*/}
              {/*<Row>*/}
              {/*  <Form.Check*/}
              {/*    type={"checkbox"}*/}
              {/*    id={`all`}*/}
              {/*    label={localization["All of the above"]}*/}
              {/*    className="unsubscribe-checkbox"*/}
              {/*    onChange={handleCheckboxChange}*/}
              {/*    checked={checkboxes["all"]}*/}
              {/*  />*/}
              {/*</Row>*/}

              <Row>
                <Button
                  variant="light"
                  type="submit"
                  onClick={handleSubmitClick}
                  disabled={isLoading}
                  className="unsubscribe-button"
                >
                  {localization["Update Preferences"]}
                </Button>
              </Row>
              <Row className="unsubscribe-footer-row">
                {localization['unsubscribe-footer-line1']}
              </Row>
              <Row className="unsubscribe-footer-row">
                {localization['unsubscribe-footer-line2']}
              </Row>
            </Container>
          )}
        </Container>
      </Container>
    </>
  );
}

export default Unsubscribe;
