export const ENV_API_ENDPOINTS = [
  { name: "dev", api: "https://low6-uf-backend-dev.azurewebsites.net" },
  { name: "uf", api: "https://low6-uf-pl-backend-prod.azurewebsites.net" },
  { name: "ufcricketdev", api: "https://low6-uf-cricket-dev.azurewebsites.net" },
  { name: "ufcricket", api: "https://low6-uf-cricket-prod-india.azurewebsites.net" },
  { name: "ufrivalry", api: "https://low6-uf-nba-prod.azurewebsites.net" },
  {
    name: "ufdenmark",
    api: "https://low6-uf-pl-denmark-backend-prod.azurewebsites.net",
  },
  {
    name: "ufbrazil",
    api: "https://low6-uf-nba-brazil-prod.azurewebsites.net",
  },
  { name: "uk", api: "https://api.low6.co.uk" },
  { name: "eu", api: "https://euprod-low6-api.low6-euprod.com" },
  { name: "us", api: "https://usprod-low6-api.low6-usprod.com" },
  { name: "us2", api: "https://usprod2-api-alb.low6-usprod.com" },
  { name: "us3", api: "https://usprod3-api-alb.low6-usprod.com" },
  { name: "animoca", api: "https://animoca-api-alb.low6-usprod.com" },
];
