import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { getTranslations } from "../helpers/getTranslations";
import { getEnvApi } from "../helpers/getEnvApi";
import { Logo } from "./Logo";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const hasNumber = (myString) => {
  return /\d/.test(myString);
};


function ResetPassword() {
  const { lang, env, tenant, token } = useParams();

  const [langState, setLangState] = useState(lang);
  const localization = getTranslations(langState);
  const envApiEndpoint = getEnvApi(env);

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [emailValidation, setEmailValidation] = useState({
    isValid: true,
    error: '',
  });
  const [passwordValidation, setPasswordValidation] = useState({
    isValid: true,
    error: '',
  });
  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState({
    isValid: true,
    error: '',
  });

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (value.length > 4) {
      const validation = validateEmail(value);
      const isValid = validation !== null;
      setEmailValidation({
        isValid,
        error: localization['Please provide a valid email'],
      });
    }
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (value.length < 8) {
      setPasswordValidation({
        isValid: false,
        error: localization['Password should be 8 characters or more'],
      });
    } else if (
      value.toUpperCase() === value ||
      value.toLowerCase() === value ||
      !hasNumber(value)
    ) {
      setPasswordValidation({
        isValid: false,
        error:
          localization[
            'The password must contain at least 1 lowercase, 1 uppercase, and 1 numeric character'
            ],
      });
    } else {
      setPasswordValidation({
        isValid: true,
        error: '',
      });
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(e.target.value);
    if (value.length > 1 && value !== password) {
      setConfirmPasswordValidation({
        isValid: false,
        error: localization['Passwords do not match'],
      });
    } else {
      setConfirmPasswordValidation({
        isValid: true,
        error: '',
      });
    }
  };
  const handleSubmitClick = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    setIsLoading(true);
    const headers = { 'x-app-id': tenant };
    axios
      .post(
        `${envApiEndpoint}/auth/passwords/reset`,
        {
          token,
          email,
          password,
          passwordConfirmation: confirmPassword,
        },
        {
          headers,
        }
      )
      .then((res) => {
        console.log(res);
        console.log(res.data);
        setIsLoading(false);
        setIsSuccess(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsSuccess(false);
        setErrorMessage(error.response?.data?.message);
      });
  };

  useEffect(() => {
    if (tenant.toLowerCase() === 'ufdenmark') {
      setLangState('da');
    }
  }, [tenant]);

  return (
    <Container>
      <Logo/>
      <Row className="justify-content-center rp-title">{localization['Password Reset']}</Row>
      <Row className="justify-content-center rp-desc">
        {isSuccess
          ? localization['Your password has now been changed. Log in with your email and new password.']
          : localization['Please enter your email address and new password.']}
      </Row>
      {isLoading && isSuccess === null && (
        <Row className="justify-content-center">
          <div className="spinner"></div>
        </Row>
      )}

      {/* Note: Hide the error message for now */}
      {/*{!isLoading && isSuccess === false && errorMessage && (*/}
      {/*  <Row className="justify-content-center">*/}
      {/*    <Alert key={'danger'} variant={'danger'}>*/}
      {/*      {localization['Error when trying to reset password, please try again']}: {errorMessage}*/}
      {/*    </Alert>*/}
      {/*  </Row>*/}
      {/*)}*/}

      {/* Note: Hide the success message for now */}
      {/*{!isLoading && isSuccess === true && (*/}
      {/*  <Row className="justify-content-center">*/}
      {/*    <Alert key={'success'} variant={'success'}>*/}
      {/*      {*/}
      {/*        localization[*/}
      {/*          'You have successfully updated your password, please return to the app and log in.'*/}
      {/*        ]*/}
      {/*      }*/}
      {/*    </Alert>*/}
      {/*  </Row>*/}
      {/*)}*/}
      {!isLoading && isSuccess === null && (
        <Row className="justify-content-center">
          <Col md="auto">
            <Form>
              <Form.Group className="mb-3 rp-input-block">
                <Form.Label>{localization['Email address']}</Form.Label>

                <Form.Control
                  id="email"
                  type="email"
                  required
                  placeholder={localization['Email address']}
                  value={email}
                  onChange={handleEmailChange}
                  isInvalid={!emailValidation.isValid}
                />
                <Form.Control.Feedback type="invalid">
                  {emailValidation.error}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3  rp-input-block">
                <Form.Label>{localization['Password']}</Form.Label>

                <Form.Control
                  id="password"
                  type="password"
                  required
                  placeholder={localization['Password']}
                  value={password}
                  onChange={handlePasswordChange}
                  isInvalid={!passwordValidation.isValid}
                />
                <Form.Control.Feedback type="invalid">
                  {passwordValidation.error}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3  rp-input-block">
                <Form.Label>{localization['Confirm Password']}</Form.Label>

                <Form.Control
                  id="confirmPassword"
                  type="password"
                  required
                  placeholder={localization['Confirm Password']}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  isInvalid={!confirmPasswordValidation.isValid}
                />
                <Form.Control.Feedback type="invalid">
                  {confirmPasswordValidation.error}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3  rp-input-block rp-button-block">
                <Button
                  variant="light"
                  type="submit"
                  onClick={handleSubmitClick}
                  disabled={
                    isLoading ||
                    !emailValidation.isValid ||
                    !passwordValidation.isValid ||
                    !confirmPasswordValidation.isValid ||
                    email.length < 4 ||
                    password.length < 4 ||
                    confirmPassword.length < 4
                  }
                >
                  {localization['Submit']}
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default ResetPassword;
