import React from "react";
import { Container, Row } from "react-bootstrap";

function CardResultSuccess() {
  return (
    <Container>
      <Row className="justify-content-center rp-title">
        Payment method added
      </Row>
      <Row className="justify-content-center rp-desc">
        Thanks, your payment method has been successfully added.
      </Row>
    </Container>
  );
}
export default CardResultSuccess;
